import { NgModule } from '@angular/core';
import { Data, Route, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { TranslationGuard } from './core/guards/translation.guard';
import { AccessUnauthorisedComponent, AppUnavailableComponent } from './features/status-pages/components';
import { ContentComponent } from './app/content/content.component';
// import { DashboardListComponent } from './features/dashboards/component/dashboard-list/dashboard-list.component';
// import { DashboardDetailComponent } from './features/dashboards/component/dashboard-detail/dashboard-detail.component';
import { DynamicRouteGuard } from './core/guards/dynamic-route.guard';
import { legacyPropertyRoutes } from './legacy.routes';
import { AssetDeepLinkGuard } from './features/property-catalog/guards/asset-deep-link.guard';
import { StatusPagesModule } from './features/status-pages/status-pages.module';
import { inviteGuard } from './core/guards/invite.guard';

// breadcrumbFilters takes a list of indexes of breadcrumbs not to show. Use this when a particular partial link does not make sense
// and would take user to a dead link.
// EG

// routes
export interface RouteData extends Data {
  // a list of indexes from the path to not show in the breadcrumbs
  breadcrumbFilters?: number[];
  // a list of indexes from the path to not translate in the breadcrumbs
  breadcrumbTranslationFilters?: number[];
  // marks the path as only being used in the background via skipLocationChange, will not be used to update breadcrumbs/breadcrumb data
  internalPath?: boolean;
  fixedHeightLayout?: boolean;
}

export interface CustomRoute extends Route {
  data?: RouteData;
  children?: CustomRoutes;
}

export type CustomRoutes = CustomRoute[];

const routes: CustomRoutes = [
  {
    path: 'properties/factsperproperty',
    children: legacyPropertyRoutes,
  },
  {
    path: 'app-access-unauthorised',
    component: AccessUnauthorisedComponent,
  },
  {
    path: 'app-unavailable',
    component: AppUnavailableComponent,
  },
  {
    path: 'invite',
    canActivate: [inviteGuard],
    loadChildren: () => import('./features/auth/auth.module'),
  },
  {
    path: 'login',
    loadChildren: () => import('./features/auth/auth.module'),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: ContentComponent,
    children: [
      {
        path: '',
        canActivate: [TranslationGuard],
        children: [
          {
            path: 'permalink',
            children: [
              {
                path: 'property-asset/:assetId',
                canActivate: [AssetDeepLinkGuard],
                children: [],
              },
            ],
          },
          {
            path: 'property-catalog',
            loadChildren: () => import('./features/property-catalog/property-catalog.module'),
            data: {
              breadcrumbFilters: [0],
            },
          },
          {
            path: 'applications/appsuite',
            loadChildren: () => import('./features/app-suite/app-suite.module'),
          },
          {
            path: 'properties/geography',
            loadChildren: () => import('./features/geo/geo.module'),
          },
          {
            path: 'file-upload',
            data: { internalPath: true }, // routes used only to load a module are marked as internal paths
            loadChildren: () => import('./features/upload/upload.module'),
          },
          {
            path: 'videos',
            data: {
              internalPath: true, // routes used only to load a module are marked as internal paths
              breadcrumbFilters: [0],
            },
            loadChildren: () => import('./features/videos/videos.module'),
          },
          {
            path: 'iframe',
            data: {
              internalPath: true, // routes used only to load a module are marked as internal paths
              breadcrumbFilters: [0],
            },
            loadChildren: () => import('./features/iframe/iframe.module'),
          },
          {
            path: 'staff',
            redirectTo: 'staffnode',
          },
          {
            path: 'vocabulary',
            data: { breadcrumbFilters: [0], breadcrumbTranslationFilters: [2] },
            loadChildren: () => import('./features/vocabulary/vocabulary.module'),
          },
          {
            path: 'projects',
            loadChildren: () => import('./features/projects/projects.module'),
          },
          {
            path: 'proca-data-quality',
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/proca-data-issues/proca-data-issues.module'),
          },
          {
            path: 'questions',
            loadChildren: () => import('./features/questions/questions.module'),
          },
          {
            path: 'pictures',
            loadChildren: () => import('./features/pictures/pictures.module'),
            data: { breadcrumbFilters: [0] },
          },
          {
            path: 'companies/companylist',
            loadChildren: () => import('./features/companies/companies.module'),
          },
          {
            path: 'staffnode/catalog',
            loadChildren: () => import('./features/staff-app/core/staff-app.module'),
          },

          // Currently unable to lazy load this module due to the way that the imported module works
          // Need Edison to maybe update this
          /*
            {
            path: 'dashboard-mvp',
            // loadChildren: () =>
            //   import('./features/modules/feature-modules/dashboards/dashboards.module').then(
            //     (m) => m.DashboardsModule
            //   ),
            children: [
              { path: '', component: DashboardListComponent },
              { path: ':id', component: DashboardDetailComponent },
            ],
          },
          */
          {
            path: 'language',
            loadChildren: () => import('./features/language/language.module'),
          },
          {
            path: 'system-info',
            loadChildren: () => import('./features/system-info/system-info.module'),
          },
          {
            path: 'search',
            loadChildren: () => import('./features/advanced-search/advanced-search.module'),
          },
          {
            path: 'proposals',
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/proposals/proposals.module'),
          },
          {
            path: 'document',
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/documents/documents.module'),
          },
          {
            path: 'assetdocuments',
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/asset-documents/asset-documents.module'),
          },
          {
            path: 'download',
            loadChildren: () => import('./features/download/download.module'),
          },
          {
            path: '**',
            canActivate: [DynamicRouteGuard],
            loadChildren: () => import('./features/navigation/navigation.module'),
            data: { loadNodes: true },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), StatusPagesModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
