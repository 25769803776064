import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { APP_CONFIG } from '../../../app.config';
import { AuthService } from '../services/auth.service';

export const inviteGuard: CanActivateFn = (route, state) => {
  const appConfig = inject(APP_CONFIG);
  const authService = inject(AuthService);

  console.log('inviteGuard');

  // If the app is not configured to process invitations, return true
  if (appConfig.auth0.processInvitations) {
    const { invitation, organization } = route.queryParams;
    console.log('processing, invitation, organization', invitation, organization);

    authService.handleInvite({ organization, invitation });
  }

  return true;
};
