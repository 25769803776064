import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-06-20T13:07:39+0000',
  },
  git: {
    branchName: 'spike/invite-feature',
    commitHash: 'd0a4fff209eb040e121220c91dee50aea70c2897',
  },
};
